
.top__nav__bar{
    color: #fff!important;
    background-color: #000;
    height: 80px;
    width: 100%;
    margin:0px;
    padding: 0px;
}
.ppnc__logo{
    width: 200px;
}

.logo__text__area{
    font-size: 26px;
    font-weight: bold;
    color:#30b700!important;
}
.top__nav__bar a {
    color: #fff;
}
.nm{
    margin:0px;
    color:#fff;

}
.nav__inc__val{
font-size: 20px;
display: flex;
margin-right: 50px;
font-weight: bold;
color: #fff!important;
}

.nav__inc__val div{
    margin-top: 2px;
    font-size: 24px;
    font-weight: bold;
}
.nav__inc__val span{
    background-color: #30b700;
    border-radius: 50px;
    padding: 8px 24px;
    font-weight: normal;
    font-size: 16px;
    margin-right: 15px;
    cursor: pointer;
}


.nav__staked{
    font-size: 20px;
    display: flex;
    margin-right: 80px;
    font-weight: bold;
    color: #fff!important;
    }
    .nav__staked div{
        margin-top: 12px;
        font-size: 24px;
        margin-right: 10px;
        font-weight: bold;
    }
    .nav__staked span{
        background-color: #30b700;
        padding: 15px;
        font-weight: normal;
        border-radius: 5px;
        font-size: 20px;
        margin-right: 15px;
        font-weight: bold;
    }

.nav__green__box__top{
background-color: #30b700;
height: 80px;
font-size: 20px;
padding:10px 10px;
text-align: center;
}
.nav__green__box__top span {
    font-weight: bold;
    color: #fff;
}

@media screen and (max-width:700px)
 {
    .nav__inc__val{
        display: none;
    }
    .ppnc__logo{
        width: 160px;
        margin-left: 20px;
    }
    
    .nav__green__box__top{
        font-size: 14px;
        font-weight: bold;
        padding-top: 20px;
     
    }
    .mobi-margin{
        margin:0px!important;
    }
    .top__nav__bar{
        margin:0px;
        padding: 0px;
    }
    .nav__staked{
        display: none;
    }

}